.App {
  @import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');
  text-align: center;
  font-family: 'Fira Sans', sans-serif;
  color: #1B1B1B;
}
.page{
  padding-left: 15px;
  padding-right: 15px;
}
.page p, li{
  font-size: 14px;
  color: #797979;
  text-align: left;
}
.page h6{
  color:#1B1B1B;
  text-align: left;
}
.Label{
  font-weight: 600;
  text-align: left;
  float: left;
  /*
  max-width: 40vw;
  min-width: 90px;
  padding-top: 6px; */
}
Table{
  font-size: 14px;
  color: #797979;
}
/* .Control{
  max-width: fit-content;
  float: right;
  max-width: 55vw;
} */
/* .text-muted{
  font-weight: 600;
  text-align: left;
  float: left;
  max-width: 40vw;
  margin-top: 1px;
}
.Form {
  margin-bottom: 15vh;
} */
.upload{
  padding: 5vw;
}

/*
  Styling for signature Pad
*/

canvas {
    max-width: 500px;
    max-height: 400px;
    border: black solid 2px;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
  }

.loaderOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}